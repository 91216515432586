<template>
  <div class="custom-container">
    <b-row class="flex-column">
      <h3 class="">
        {{ $t('secondRound') }}
      </h3>
      <span class="text-muted">
        {{ $t('imageInfo') }}
      </span>
    </b-row>
    <b-row class="mt-2">
      <b-col class="p-0">
        <pricing-table :items="items" />
      </b-col>
    </b-row>
    <b-row
      v-for="(category, n) in categories"
      :key="n"
      class="mt-2"
    >
      <b-col class="p-0">
        <h1>{{ category.name }}</h1>
        <pricing-list
          :round-stage="'secondRound'"
          :products="category.products"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3 justify-content-end">
      <b-button
        size="lg"
        variant="primary"
      >
        {{ $t('sendQuote') }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PricingTable from '@/components/pricing/BasicTable.vue'
import PricingList from '@/components/pricing/PricingList.vue'

export default {
  components: {
    PricingTable,
    PricingList,
  },

  data() {
    return {
      items: [
        {
          folio: '91716',
          address: 'Depotivo Cumbres',
          municipality: 'Monterrey, N.L.',
          postal_code: '67110',
          date: '06 Ago 2021',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('products', {
      categories: 'productsGroupedByCategory',
    }),
  },
}
</script>

<style></style>
